import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { HeaderComponent } from './components/header/header.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { ImageModule } from '@teamfoster/sdk/image';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { IconModule } from '@teamfoster/sdk/icon';
// import { SplittingDirective } from './directives/splitting.directive';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { NgxGlideModule } from 'ngx-glide';
import { SliderComponent } from './components/slider/slider.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ParallaxDirective, ShowBrowserSpeedDirective } from './directives';
import { ImageComponent } from './components/image/image.component';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';

const moduleExports = [
  LoaderIndicatorComponent,
  HeaderComponent,
  TopbarComponent,
  FooterComponent,
  NewsCardComponent,
  ImageModalComponent,
  SliderComponent,
  ReversePipe,
  ParallaxDirective,
  ImageComponent,
  ShowBrowserSpeedDirective,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    InViewModule,
    Html5VideoPlayerModule,
    IconModule,
    ImageModule,
    NgxGlideModule,
    CookieNgrxModule,
    DictionaryNgrxModule,
  ],
  declarations: [...moduleExports],
  exports: [...moduleExports],
  providers: [],
})
export class CoreModule {}
